import {ethers, utils} from "ethers";
import configs from "../config/test_config.json";
import marketAbi from "./marketAbi.json";
import erc1155Abi from "./erc1155Abi.json";
import approveAbi from "./approveAbi.json";
import redeemAbi from "./redeemAbi.json";
import auctionAbi from "./auctionAbi.json";
import whitelistBurnerAbi from "./whitelistBurnerAbi.json";
import {
  Contract as MultiCallContract,
  Provider as MultiCallProvider,
} from "ethers-multicall";

let contract;
let approveContract;
let redeemContract;
let whitelistBurnerContract;
let auctionContract;

export const providerHandler = async () => {
  console.log('==> [enter] CONINT/providerHandler')
  
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const account = await provider.listAccounts();
  const address = account[0];
  const signer = provider.getSigner();

  contract = new ethers.Contract(configs.marketAddress, marketAbi, signer);
  approveContract = new ethers.Contract(configs.approveAddress, approveAbi, signer);
  redeemContract = new ethers.Contract(configs.redeemAddress, redeemAbi, signer);
  whitelistBurnerContract = new ethers.Contract(configs.WhitelistBurnerAddress, whitelistBurnerAbi, signer);
  auctionContract = new ethers.Contract(configs.auctionAddress, auctionAbi, signer);
  
  console.log('[output] providerHandler/address: ', address);
  console.log('<== [exit] CONINT/providerHandler')
  
  return address;
};

export const getPurchasedItems = async (userAddress, items) => {
  try {
    console.log('==> [enter] CONINT/getPurchasedItems')
    
    console.log('[input] getPurchasedItems/userAddress: ', userAddress)
    const tokens = items.map((item) => item.tokenId);
    console.log('[input] getPurchasedItems/tokens: ', tokens);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const ethcallProvider = new MultiCallProvider(provider);
    await ethcallProvider.init();
    const multiCallContract = new MultiCallContract(configs.ERC1155address, erc1155Abi);
  
    const purchasedItems = [];
    const calls = [];

    for (let i = 0; i < tokens.length; i++) {
      const res = await multiCallContract.balanceOf(userAddress, tokens[i])
      calls.push(res)
    }

    const results = await ethcallProvider.all(calls);
    console.log('[multicall] getPurchasedItems/results');
    
    for (let i in tokens) {
      if (results[i].toNumber() !== 0) {
        let item = items.find((item) => item.tokenId === tokens[i]);
        if (item !== undefined) {
          item.vouchers = results[i].toNumber();
          purchasedItems.push(item);
        }
      }
    }
    
    console.log('[output] getPurchasedItems/purchasedItems: ', purchasedItems)
    console.log('<== [exit] CONINT/getPurchasedItems')

    return purchasedItems;
  } catch (error) {
    console.log("[ERROR] CONINT/getPurchasedItems", error);
    console.log('<== [exit] CONINT/getPurchasedItems')
    return [];
  }
};

export const getTokenData = async (userAddress, items) => {
  try {
    console.log('==> [enter] CONINT/getTokenData')
    
    console.log('[input] getTokenData/userAddress: ', userAddress);
    const tokens = items.map((item) => item.tokenId);
    console.log('[input] getTokenData/tokens: ', tokens);
    
    const categories = ['All Categories'];
    const currentTime = Date.now() / 1000;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const ethcallProvider = new MultiCallProvider(provider);
    await ethcallProvider.init();
    const multiCallContract = new MultiCallContract(configs.marketAddress, marketAbi);
  
    const tokenPriceCalls = tokens.map((token) => {
      return multiCallContract.getTokenPrice(token);
    });
    const tokenPrice = await ethcallProvider.all(tokenPriceCalls);
    console.log('[multicall] getTokenData/tokenPrice');

    const getTotalSupply = tokens.map((token) => {
      return multiCallContract.getMaxSupply(token);
    });
    const totalSupply = await ethcallProvider.all(getTotalSupply);
    console.log('[multicall] getTokenData/totalSupply');

    const getMaxTokenAllowed = tokens.map((token) => {
      return multiCallContract.maxTokenToMintPerAddress(token);
    });
    const maxTokenAllowedResult = await ethcallProvider.all(getMaxTokenAllowed);
    const maxTokenAllowed = maxTokenAllowedResult.map((item) => {
      return item.toNumber();
    });
    console.log('[multicall] getTokenData/maxTokenAllowed');

    const getTokenMinted = tokens.map((token) => {
      return multiCallContract.getTotalTokenMintedByUser(userAddress, token);
    });
    const tokenMintedResult = await ethcallProvider.all(getTokenMinted);
    const tokenMinted = tokenMintedResult.map((item) => {
      return item.toNumber();
    });
    console.log('[multicall] getTokenData/tokenMinted');

    const getDutchDetails = tokens.map((token) => {
      return multiCallContract.auctionConditions(token);
    });
    const allDetails = await ethcallProvider.all(getDutchDetails);
    console.log('[multicall] getTokenData/allDetails');

    const allItems = await items.map((item, i) => {
      categories.push(item.category)
      item.price = ethers.utils.formatEther(tokenPrice[i]);
      item.remainingToken = maxTokenAllowed[i] - tokenMinted[i];
      item.totalRemaining = totalSupply[i].toNumber();
      if (item.itemType === 2) {
        item.dutchDetails = {
          startingPrice: ethers.utils.formatEther(allDetails[i].startingPrice),
          minimumPrice: ethers.utils.formatEther(allDetails[i].minimumPrice),
          reducingTimeQuantum: allDetails[i].reducingTimeQuantum.toNumber(),
          reducingPrice: ethers.utils.formatEther(allDetails[i].reducingPrice)
        }
      }
      item.isDisabled = currentTime < item.startTime;
      return item
    })
    
    console.log('[output] getTokenData/allItems: ', allItems)

    console.log('<== [exit] CONINT/getTokenData');

    return { allItems, categories };
  } catch (error) {
    console.log('[ERROR] CONINT/getTokenData: ', error)
    console.log('<== [exit] CONINT/getTokenData');
    return { allItems: [], categories: [] };
  }
};

export const auctionDetails = async (auctionId) => {
  console.log('==> [enter] CONINT/auctionDetails');
  console.log('[input] auctionDetails/auctionId: ', auctionId);
  
  const n = await auctionContract.auctionSettings(auctionId)
  console.log('[output] auctionDetails/auctionSettings');
  console.log('<== [exit] CONINT/auctionDetails');

  return {
    bidsAllowedPerUser: n.bidsAllowedPerUser.toNumber(),
    endTime: n.endTime.toNumber(),
    maxWinnerAllowed: n.maxWinnerAllowed.toNumber(),
    minBid: ethers.utils.formatEther(n.minBid),
    startTime: n.startTime.toNumber(),
    startingBid: ethers.utils.formatEther(n.startingBid)
  }
}

export const allSpecialAuctionIds = async () => {
  const n = await auctionContract.getCurrentSpecialAuctionIds()
  return n.map((item) => item.toNumber());
}

export const limitInSpecialAuction = async () => {
  const n = await auctionContract.specialConditionLimit()
  return n.toNumber();
}

export const currentBidsInSpecialAuction = async (account) => {
  const n = await auctionContract.currentBidsInAuction(account)
  return n.toNumber();
}

export const allBids = async (auctionId) => {
  const n = await auctionContract.getAuctionWinners(auctionId)
  return n;
}

export const lowestBidAmount = async (auctionId) => {
  const n = await auctionContract.getLowestBid(auctionId)
  return ethers.utils.formatEther(n.toString());
}

export const bidsAllowedPerUser = async (auctionId, userAddress) => {
  const n = await auctionContract.totalBidsInAuctionByUser(auctionId, userAddress);
  console.log('[log] CONINT/bidsAllowedPerUser: ',n)
  return n;
};

export const Signer = async (userAddress, contractAddress, name) => {
  try {
    const timestamp = Math.floor(Date.now() / 1000);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const domain = {
      name: name,
      version: "1",
      chainId: 137,
      verifyingContract: contractAddress,
    };

    const types = {
      GasGuzzlersSignature: [
        { name: "userAddress", type: "address" },
        { name: "timestamp", type: "uint256" },
      ],
    };

    const value = {
      userAddress: userAddress,
      timestamp: timestamp,
    };
    const signature = await signer._signTypedData(domain, types, value);
    return [timestamp, signature];
  } catch (error) {
    console.log("[ERROR] CONINT/Signer: ", error);
    return [];
  }
};

export const Signature = async () => {
  try {
    const timestamp = Math.floor(Date.now() / 1000);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(timestamp.toString())
    return { message: timestamp.toString(), signature, address: await signer.getAddress() };
  } catch (error) {
    console.log("[ERROR] CONINT/Signature: ", error);
    return false;
  }
};

export const isHashUsed = async (txHash) => {
  const n = await redeemContract.isHashUsed(txHash)
  return n;
};

export const balanceOf = async (account) => {
  console.log('==> [enter] CONINT/balanceOf')
  console.log('[input] balanceOf/account: ', account)

  const n = await approveContract.balanceOf(account);
  // console.log(n);
  const balance = ethers.utils.formatEther(n);
  console.log('[output] balanceOf/balance: ', balance)
  console.log('<== [exit] CONINT/balanceOf')

  return balance.toString();
};

export const allowance = async (account, contractAddress) => {
  const n = await approveContract.allowance(account, contractAddress);
  // console.log(n);
  return n.toString();
};

export const getWhitelistAddresses = async (tokenId) => {
  const n = await whitelistBurnerContract.getWhitelistAddresses(tokenId);
  // console.log(n);
  return n
};

export const approve = async (contractAddress) => {
  try {
    const n = await approveContract.approve(contractAddress, configs.approveAmount);
    return await n.wait();
  } catch (error) {
    console.log("[ERROR] CONINT/approve: ", error);
    return false
  }
};

