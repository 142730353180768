import React, {useEffect, useState} from "react";
import './shop.scss';
import LeftColumn from "./layout/LeftColumn";
import CenterColumn from "./layout/CenterColumn";
import CartModal from "./layout/CartModal";
import {buyProducts, checkDiscordUserName, deleteRaffle, getListOfRaffleOwners, placeBid} from "../../utils/backendApi";
import {
  allBids,
  allowance, allSpecialAuctionIds,
  approve,
  auctionDetails,
  bidsAllowedPerUser, currentBidsInSpecialAuction,
  getWhitelistAddresses, limitInSpecialAuction, lowestBidAmount,
  Signer
} from "../../web3/contractInteraction";
import {toast} from "react-toastify";
import configs from "../../config/test_config.json";
import RightColumn from "./layout/RightColumn";
import WalletListModal from "./layout/WalletListModal";
import RaffleModal from "./layout/RaffleModal";
import CloseRaffleModal from "./layout/CloseRaffleModal";
import BidModal from "./layout/BidModal";

const Shop = ({
                toastOptions,
                handleScroll,
                setLoading,
                account,
                getProducts,
                allVouchers,
                chainId,
                handlePolygonChainChange,
                allCategories,
                isScrolling,
                setPostTweet,
                setLoadingMessage,
                balance,
                isAdmin
              }) => {
  const [selectedFilter, setSelectedFilter] = useState('All Categories');
  const [selectedCard, setSelectedCard] = useState();
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [filteredCard, setFilteredCard] = useState([]);
  const [walletListOpen, setWalletListOpen] = useState(false);
  const [raffleModalOpen, setRaffleModalOpen] = useState(false);
  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [closeRaffleModalOpen, setCloseRaffleModalOpen] = useState(false);
  const [selectedRaffleVoucher, setSelectedRaffleVoucher] = useState();
  const [ticketAmount, setTicketAmount] = useState(1);
  const [bidVoucher, setBidVoucher] = useState();
  const [bidAmount, setBidAmount] = useState(0);
  const [bidApprove, setBidApprove] = useState(false);
  const [walletListVoucher, setWalletListVoucher] = useState();
  const [walletListDetails, setWalletListDetails] = useState([]);
  const [raffleOwnersList, setRaffleOwnersList] = useState([]);
  const [hoveredCard, setHoveredCard] = useState();
  const modalRef1 = React.useRef(null);
  const modalRef2 = React.useRef(null);
  const modalRef3 = React.useRef(null);
  const modalRef4 = React.useRef(null);
  const modalRef5 = React.useRef(null);
  const [flag, setFlag] = useState();

  useEffect(() => {
    handlePolygonChainChange()
  }, [chainId]);

  useEffect(() => {
    setCart([])
  }, []);

  useEffect(() => {
    console.log('[log] useEffect/SHOP/account: ', account)

    setOpenBuyModal(false)
    setRaffleModalOpen(false)
    setCloseRaffleModalOpen(false)
    setBidModalOpen(false)
    setWalletListOpen(false)
    setCart([])
    setSelectedCard()
  }, [account])

// for count total price of all products which are in cart
  useEffect(() => {
    let sum = 0;
    cart?.map((cartItem) => {
      sum += cartItem.price * cartItem.Qty
    })
    setTotalPrice(sum)
  }, [cart]);

  // for buy and products from our shop
  const handleBuyProducts = async () => {
    if (modalRef1.current !== null) {
      modalRef1.current.classList.add("out");
    }
    setTimeout(() => {
      setLoading(true)
      setOpenBuyModal(false)
    }, 400)
    const response = await checkDiscordUserName(account);
    if (response) {
      const sign = await Signer(account, configs.marketAddress, configs.domainName)
      if (sign.length > 0) {
        setLoadingMessage(true)
        let dataParams = { tokenID: [], quantity: [], userAddress: account, timestamp: sign[0], signature: sign[1] };
        cart.map((item) => {
          dataParams.tokenID.push(item.tokenId)
          dataParams.quantity.push(item.Qty)
        })
        const res = await buyProducts(dataParams)
        if (res) {
          // toast('Your purchase was successful!', toastOptions)
          setPostTweet({ isSuccessful: true, transactionType: 'purchase' })
        } else {
          toast('Something went wrong. Please try again.', toastOptions)
        }
      } else {
        toast('Signature request was denied.', toastOptions)
      }
      setLoadingMessage(false);
      getProducts()
    } else {
      toast('Sorry, you do not have any holder roles on Discord', toastOptions)
    }
    setCart([])
    setFlag()
    setLoading(false)
  }
  // remove item from cart
  const handleRemove = (key) => {
    // for product swipe animation when it remove from cart
    setFlag(key)
    setTimeout(() => {
      setCart(cart.filter((j) => {
        return j.tokenId !== key
      }));
      setFlag()
    }, [500])
  };

  //for clear cart button
  const handleRemoveAll = () => {
    // for product swipe animation when it remove from cart
    setFlag('deleteAll')
    setTimeout(() => {
      setCart([]);
      setFlag()
    }, [500])
  };

  const handleSetApproval = async () => {
    setLoading(true)
    setLoadingMessage(true)
    const approval = await approve(configs.marketAddress)
    if (approval) {
      toast('Transaction successful!', toastOptions)
    } else if (approval === false) {
      toast('User rejected transaction!', toastOptions)
    }
    setLoadingMessage(false)
    handleCheckApproval()
    setLoading(false)
  }

  const handleCheckApproval = async () => {
    const approveAmount = await allowance(account, configs.marketAddress)
    if (approveAmount == 0 || Number(approveAmount) < totalPrice) {
      setIsApproved(true)
    } else {
      setIsApproved(false)
    }
  }

  //for separate total by coma
  const commaSeparateNumber = (val) => {
    while (/(\d+)(\d{3})/.test(val?.toString())) {
      val = val?.toString().replace(/(\d+)(\d{3})/, `$1,$2`);
    }
    return val;
  };

  const handleShowWalletList = async (e, voucher) => {
    e.stopPropagation()
    setLoading(true)
    const walletAddresses = await getWhitelistAddresses(voucher.tokenId)
    setWalletListDetails(walletAddresses)
    setLoading(false)
    setWalletListOpen(true)
    setWalletListVoucher(voucher)
  }

  const handleCloseWalletList = () => {
    if (modalRef2.current !== null) {
      modalRef2.current.classList.add("out");
    }
    setTimeout(() => {
      setWalletListOpen(false);
      setWalletListDetails([])
      setWalletListVoucher()
    }, 400)
  }

  const handleHovered = (item) => {
    setHoveredCard(item)
  }

  const handleMouseLeave = () => {
    setHoveredCard(selectedCard)
  }

  const onCloseClick = () => {
    if (modalRef1.current !== null) {
      modalRef1.current.classList.add("out");
    }
    setTimeout(() => {
      setOpenBuyModal(false)
    }, 400)
  };

  const handleOpenRaffleModal = async (e, item) => {
    e.stopPropagation()
    setLoading(true)
    setTicketAmount(1)
    setSelectedRaffleVoucher(item)
    const ownerListOfToken = await getListOfRaffleOwners(item.tokenId)
    if (ownerListOfToken) {
      setRaffleOwnersList(ownerListOfToken)
    } else {
      setRaffleOwnersList([])
    }
    setRaffleModalOpen(true)
    setLoading(false)
  }

  const handleExitFromRaffleModal = () => {
    if (modalRef3.current !== null) {
      modalRef3.current.classList.add("out");
    }
    setTimeout(() => {
      document.getElementById('tickets').value = null;
      setRaffleModalOpen(false)
      setSelectedRaffleVoucher()
      setTicketAmount(0)
      setRaffleOwnersList([])
    }, 400)
  }

  const handleGetTicketAmount = (e) => {
    if (e.target.value.length) {
      setTicketAmount(Number(e.target.value))
    } else {
      setTicketAmount('')
    }
  }

  const handleOpenConfirmationModal = () => {
    setCloseRaffleModalOpen(true)
  }

  const handleDeleteAllRaffles = async () => {
    if (modalRef4.current !== null) {
      modalRef4.current.classList.add("out");
      modalRef3.current.classList.add("out");
    }
    setTimeout(() => {
      setRaffleModalOpen(false)
      setCloseRaffleModalOpen(false)
    }, 400)
    setLoading(true)
    const sign = await Signer(account, configs.marketAddress, configs.domainName)
    if (sign.length > 0) {
      setLoadingMessage(true)
      const dataParams = {
        tokenId: selectedRaffleVoucher.tokenId,
        userAddress: account,
        timestamp: sign[0],
        signature: sign[1]
      }

      const response = await deleteRaffle(dataParams)
      if (response) {
        toast('Raffle has been deleted.', toastOptions)
      } else {
        toast('Something went wrong. Please try again.', toastOptions)
      }
    } else {
      toast('Signature request was denied.', toastOptions)
    }
    setSelectedRaffleVoucher()
    setTicketAmount(0)
    setRaffleOwnersList([])
    getProducts()
    setLoading(false)
  }

  const handleCloseConfirmationModal = () => {
    if (modalRef4.current !== null) {
      modalRef4.current.classList.add("out");
    }
    setTimeout(() => {
      setCloseRaffleModalOpen(false)
    }, 400)
  }

  const handleBidOpen = async (item, e) => {
    e.stopPropagation()
    setLoading(true)
    bidApproveCheck()

    function msToTime(duration) {
      let a = Math.floor((duration) / (1000 * 60 * 60 * 24))
      var minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      a = (a < 10) ? "0" + a : a;
      return a + "D " + hours + "H " + minutes + "M";
    }

    item.auctionDetails = await auctionDetails(item.auctionId)
    item.allBids = await allBids(item.auctionId)
    const uniqueUsers = item.allBids[0]?.filter((v, i, a) => a.indexOf(v) === i);
    item.totalBidders = uniqueUsers?.length
    const timestamp = Math.floor(Date.now() / 1000);
    if (timestamp < item.auctionDetails.endTime) {
      item.auctionDetails.auctionDuration = msToTime((item.auctionDetails.endTime - timestamp) * 1000)
    } else {
      item.auctionDetails.auctionDuration = "auction over"
    }
    if (item.allBids[0].length >= item.auctionDetails.maxWinnerAllowed) {
      const lowestBid = await lowestBidAmount(item.auctionId)
      item.currenBidAmount = Number(lowestBid) + Number(item.auctionDetails.minBid)
    } else {
      item.currenBidAmount = Number(item.auctionDetails.startingBid)
    }
    const pastBids = await bidsAllowedPerUser(item.auctionId, account)
    item.isBidAllowed = pastBids < item.auctionDetails.bidsAllowedPerUser;

    const specialIds = await allSpecialAuctionIds()
    if (specialIds.includes(item.auctionId)) {
      const involvedSpecialAuction = await currentBidsInSpecialAuction(account)
      const specialAuctionLimit = await limitInSpecialAuction()
      if (involvedSpecialAuction >= specialAuctionLimit) {
        const isUserExist = await item.allBids[0].some(wallet => wallet.toLowerCase() === account.toLowerCase())
        if (!isUserExist) {
          item.isLimitOver = true
        }
      }
    }
    setBidVoucher(item)
    setLoading(false)
    setBidModalOpen(true)
  }

  const handleGetBidAmount = (e) => {
    setBidAmount(Number(e.target.value))
  }

  const handleBidModalClose = () => {
    if (modalRef5.current !== null) {
      modalRef5.current.classList.add("out");
    }

    if (bidVoucher?.auctionDetails.auctionDuration !== "auction over") {
      document.getElementById('amount').value = null;
    }

    setTimeout(() => {
      setBidModalOpen(false)
      setBidVoucher()
      setBidAmount(0)
    }, 400)
  }

  useEffect(() => {
    bidApproveCheck()
  }, [bidAmount]);

  const bidApproveCheck = async () => {
    const approveAmount = await allowance(account, configs.auctionAddress)
    if (approveAmount == 0 || Number(approveAmount) < bidAmount) {
      setBidApprove(true)
    } else {
      setBidApprove(false)
    }
  }

  const handleApproveBidAmount = async () => {
    setLoading(true)
    const approval = await approve(configs.auctionAddress)
    if (approval) {
      toast('Transaction successful!', toastOptions)
      setBidApprove(false)
    } else if (approval === false) {
      setBidApprove(true)
      toast('User rejected transaction!', toastOptions)
    }
    setLoading(false)
  }


  const handlePlaceBid = async () => {
    if (modalRef5.current !== null) {
      modalRef5.current.classList.add("out");
    }
    setTimeout(() => {
      document.getElementById('amount').value = null;
      setBidModalOpen(false)
    }, 400)
    setLoading(true)
    const response = await checkDiscordUserName(account);
    if (response) {
      let signatureTuple = await Signer(account, configs.auctionAddress, configs.auctionName)
      if (signatureTuple.length > 0) {
        setLoadingMessage(true)
        const dataParams = {
          auctionId: bidVoucher?.auctionId,
          bidAmount: bidAmount.toString(),
          userAddress: account,
          timestamp: signatureTuple[0],
          signature: signatureTuple[1]
        }
        const response = await placeBid(dataParams)
        if (response) {
          toast('Your bid was placed successfully.', toastOptions)
        } else {
          toast('Something went wrong. Please try again.', toastOptions)
        }
      } else {
        toast("Signature request was denied.", toastOptions)
      }
    } else {
      toast('Sorry, you do not have any holder roles on Discord', toastOptions)
    }
    setLoadingMessage(false)
    setBidVoucher()
    setBidAmount(0)
    getProducts()
  }

  return (
    <>
      <div className='shop_main_div'>
        <LeftColumn allVouchers={allVouchers}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                    setFilteredCard={setFilteredCard}
                    allCategories={allCategories}
                    hoveredCard={hoveredCard}
        />
        <CenterColumn cart={cart}
                      setCart={setCart}
                      selectedCard={selectedCard}
                      setSelectedCard={setSelectedCard}
                      filteredCard={filteredCard}
                      handleShowWalletList={handleShowWalletList}
                      handleHovered={handleHovered}
                      handleScroll={handleScroll}
                      isScrolling={isScrolling}
                      handleMouseLeave={handleMouseLeave}
                      handleBidOpen={handleBidOpen}
                      isAdmin={isAdmin}
                      handleOpenRaffleModal={handleOpenRaffleModal}
        />
        <RightColumn cart={cart}
                     flag={flag}
                     handleRemove={handleRemove}
                     commaSeparateNumber={commaSeparateNumber}
                     totalPrice={totalPrice}
                     setOpenBuyModal={setOpenBuyModal}
                     handleRemoveAll={handleRemoveAll}
                     account={account}
                     handleSetApproval={handleSetApproval}
                     isApproved={isApproved}
                     handleCheckApproval={handleCheckApproval}
                     balance={balance}/>
      </div>
      <CartModal commaSeparateNumber={commaSeparateNumber}
                 totalPrice={totalPrice}
                 openBuyModal={openBuyModal}
                 cart={cart}
                 modalRef={modalRef1}
                 onCloseClick={onCloseClick}
                 handleRemove={handleRemove}
                 flag={flag}
                 balance={balance}
                 handleBuyProducts={handleBuyProducts}
      />
      <WalletListModal walletListVoucher={walletListVoucher}
                       walletListDetails={walletListDetails}
                       modalRef={modalRef2}
                       handleCloseWalletList={handleCloseWalletList}
                       walletListOpen={walletListOpen}
      />
      <RaffleModal modalRef={modalRef3}
                   raffleModalOpen={raffleModalOpen}
                   handleExitFromRaffleModal={handleExitFromRaffleModal}
                   selectedRaffleVoucher={selectedRaffleVoucher}
                   handleGetTicketAmount={handleGetTicketAmount}
                   handleOpenConfirmationModal={handleOpenConfirmationModal}
                   ticketAmount={ticketAmount}
                   setLoading={setLoading}
                   raffleOwnersList={raffleOwnersList}
      />
      <CloseRaffleModal closeRaffleModalOpen={closeRaffleModalOpen}
                        modalRef={modalRef4}
                        handleDeleteAllRaffles={handleDeleteAllRaffles}
                        handleCloseConfirmationModal={handleCloseConfirmationModal}
      />
      <BidModal modalRef={modalRef5}
                bidApprove={bidApprove}
                bidVoucher={bidVoucher}
                handleGetBidAmount={handleGetBidAmount}
                bidModalOpen={bidModalOpen}
                bidAmount={bidAmount}
                balance={balance}
                handleApproveBidAmount={handleApproveBidAmount}
                handlePlaceBid={handlePlaceBid}
                handleBidModalClose={handleBidModalClose}
      />
    </>
  )
}
export default Shop;
